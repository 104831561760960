import * as Sentry from '@sentry/react'
import ky from 'ky'
import { configure } from 'mobx'
import React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom'
import { App } from 'App'
import { configureAmplify } from 'config/configureAmplify'
import configureAssetLinks from 'config/configureAssetLinks'
import { configureGoogleAnalytics } from 'config/configureGoogleAnalytics'
import { configureSentry } from 'config/configureSentry'
import 'index.css'
import { Providers } from 'providers'
import { ConfigLoadErrorPage } from 'routes/ConfigLoadErrorPage'
import { LoadingPage } from 'routes/LoadingPage'
import { ClientConfigDTO } from 'interfaces/Config'

configure({
  enforceActions: 'always'
})

ReactDOM.render(<LoadingPage />, document.getElementById('root'))

// TODO initial config load can be refactored after redux store is removed/refactored
ky.get('/api/v2/client-config', { timeout: 25000 })
  .json()
  .then(config => {
    const conf = config as ClientConfigDTO
    if (conf.enableSentry) {
      configureSentry(conf.envName)
    }

    configureAmplify(conf.cognitoRegion, conf.cognitoEcmUserPoolId, conf.cognitoEcmAppClientId)
    configureGoogleAnalytics(conf.googleAnalyticsTrackingId)
    configureAssetLinks.set(conf.assetsBucketUri)
    ReactDOM.render(
      <Providers>
        <App config={conf} />
      </Providers>,
      document.getElementById('root')
    )
  })
  .catch((error) => {
    console.error('ConfigLoadError', error)
    configureSentry()
    Sentry.captureException('ConfigLoadError')
    ReactDOM.render(<ConfigLoadErrorPage />, document.getElementById('root'))
  })
